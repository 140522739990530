import React, { Fragment, useContext, useState } from "react";
/// React router dom
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";
import jharkhandlogo1 from "../../../images/jharkhandlogo1.png";
import jharkhandlogo2 from "../../../images/jharkhandlogo2.png";
import jharkhandlogo3 from "../../../images/jharkhand logo3.png";
import "./style.css";

export function NavMenuToggle() {
  setTimeout(() => {
    let mainwrapper = document.querySelector("#main-wrapper");
    if (mainwrapper.classList.contains("menu-toggle")) {
      mainwrapper.classList.remove("menu-toggle");
    } else {
      mainwrapper.classList.add("menu-toggle");
    }
  }, 200);
}

const NavHader = () => {
  const loginDetails = localStorage.getItem("login_Details");
  const loginFullDetails = JSON.parse(loginDetails);
  const [toggle, setToggle] = useState(false);
  const { navigationHader, background } = useContext(ThemeContext);
  return (
    <div className="nav-header">
      <Link to="/dashboard" className="brand-logo py-2">
        {background.value === "dark" || navigationHader !== "color_1" ? (
          <Fragment>
            <div className="d-flex">
              <div>
                <img src={jharkhandlogo1} alt="logo" />
              </div>
              <div>
                <img src={jharkhandlogo2} alt="logo" />
              </div>
              <div>
                <img src={jharkhandlogo3} alt="logo" />
              </div>
            </div>

            <div className="brand-title">
              <span className="brand-sub-title">
                {loginFullDetails?.user?.name}
              </span>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div className="d-flex ">
              <div>
                <img
                  src={jharkhandlogo1}
                  alt="logo"
                  className={`${toggle ? "logo_img_small" : "logo_img"}`}
                />
              </div>
              <div>
                <img
                  src={jharkhandlogo2}
                  alt="logo"
                  className={`${toggle ? "logo_img_small" : "logo_img"}`}
                />
              </div>
              <div>
                <img
                  src={jharkhandlogo3}
                  alt="logo"
                  className={`${toggle ? "logo_img_small" : "logo_img"}`}
                />
              </div>
            </div>
            <div className="brand-title">
              <span className="brand-sub-title">
                {loginFullDetails?.user?.name}
              </span>
            </div>
          </Fragment>
        )}
      </Link>

      <div
        className="nav-control"
        onClick={() => {
          setToggle(!toggle);
          NavMenuToggle();
        }}
      >
        <div className={`hamburger ${toggle ? "is-active" : ""}`}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </div>
    </div>
  );
};

export default NavHader;
