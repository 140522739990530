import {
  addGrievanceTicketEndPoint,
  addGrievanceTicketReplyEndPoint,
  addUserGrievanceTicketReplyEndPoint,
  getAllGrievanceTicketEndPoint,
  getAllUserGrievanceTicketEndPoint,
  getSingleGrievanceTicketEndPoint,
  getSingleUserGrievanceTicketEndPoint,
  updateStatusPriorityGrievanceTicketEndPoint,
} from "../../../config/adminUrlConfig";
import { ApiService, FileUploadService } from "../../../config/apiServices";

export const addGrievanceTicket = async (paylaod) => {
  try {
    return FileUploadService.post(addGrievanceTicketEndPoint, paylaod);
  } catch (error) {
    return error.message;
  }
};
export const getGrievanceAllTicket = async (paylaod) => {
  const { priority, status, search } = paylaod;
  let url = `${getAllGrievanceTicketEndPoint}?page=${paylaod?.currentpage}&`;
  if (priority) {
    url += `prority_level=${priority}&`;
  }
  if (status) {
    url += `status=${status}&`;
  }
  if (search) {
    url += `search=${search}&`;
  }
  return ApiService.get(url);
};
export const getGrievanceAllUserTicket = async (paylaod) => {
  return ApiService.get(
    `${getAllUserGrievanceTicketEndPoint}?page=${paylaod?.currentpage}`
  );
};
export const getSingleData = async (id) => {
  return ApiService.get(`${getSingleGrievanceTicketEndPoint}/${id}`);
};
export const getSingleUserData = async (id) => {
  return ApiService.get(`${getSingleUserGrievanceTicketEndPoint}/${id}`);
};
export const addSingleTicketResponse = async (FormData) => {
  return FileUploadService.post(addGrievanceTicketReplyEndPoint, FormData);
};

export const addUserSingleTicketResponse = async (FormData) => {
  return FileUploadService.post(addUserGrievanceTicketReplyEndPoint, FormData);
};

export const updateStausAndPriority = (payload) => {
  console.log(payload);
  return ApiService.post(
    `${updateStatusPriorityGrievanceTicketEndPoint}`,
    payload
  );
};
