import React from "react";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";

import {
  getAllNotificationDetails,
  updateWebsiteNotificationStatus,
} from "../../../../services/adminApiService/communicateApi/communicateApi";
import { Button } from "react-bootstrap";
import Communication from "../Communication";
import swal from "sweetalert";
import PageTitle from "../../../../jsx/layouts/PageTitle";
import PreviewCommunicationModal from "../PreviewCommunicationModal";
import DateRangePicker from "react-bootstrap-daterangepicker";
import PaginationComp from "../../../../common/ui/view/pagination/PaginationComp";

// const ALLOWED_CHARACTERS_REGEX = /^[a-zA-Z0-9\s()]*$/;
// const ALLOWED_CHARACTERS_REGEX = /^[a-zA-Z0-9\s\()\.\,\-\!\:\&]*$/;
const ALLOWED_CHARACTERS_REGEX = /^.*$/; //sepcial charatcter

const CardHeader = ({
  handleSearchValue,
  searchByTitle,
  handleApply,
  Error,
}) => {
  return (
    <div className="card-header d-flex gap-2">
      <div>
        <div className="input-group search-area">
          <input
            value={searchByTitle}
            onChange={handleSearchValue}
            type="text"
            className={`form-control `}
            placeholder="Search here..."
          />
          <span className="input-group-text">
            <Link to={"#"}>
              <i className="flaticon-381-search-2"></i>
            </Link>
          </span>
        </div>
        <span className="validation-text text-danger">
          {Error.titleError && Error.titleError}
        </span>
      </div>
      <div>
        <DateRangePicker onApply={handleApply}>
          {/* <input type="text" className="form-control" /> */}
          <Button className="form-control btn-sm">Search By Date</Button>
        </DateRangePicker>
      </div>
    </div>
  );
};
export const formatOFAttachmentRegex = /\.(pdf)$/i;

function WebsiteNotification() {
  const [data, setData] = React.useState([]);
  const [previewDocId, previewDetdocId] = React.useState(null);
  const [notificationFor, setnotificationFor] = React.useState("");
  const [searchByTitle, setsearchByTitle] = React.useState("");
  const debounceTimeout = React.useRef(null);
  const [Error, setError] = React.useState({ titleError: "" });
  const [dateRange, setDateRange] = React.useState({
    startDate: "",
    endDate: "",
  });
  const [isLoading, setisLoading] = React.useState(false);

  const [totalPages, setTotalPages] = React.useState(null); //total pages
  const [currentpage, setCurrentPage] = React.useState(1); //current pages
  // fetchData
  async function fetchData(payload = {}, currentpage) {
    setisLoading(true);
    try {
      let res = await getAllNotificationDetails(payload, currentpage);
      setTotalPages(res?.data?.data?.last_page);

      let data = res?.data?.data?.data?.map((ele) => ele);
      setData(data);
    } catch (error) {
      console.log(error.message);
    } finally {
      setisLoading(false);
    }
  }
  // Initial data fetch
  React.useEffect(() => {
    // Communicate Data for Listing
    fetchData({}, currentpage);
  }, [currentpage]);

  const handleStatus = async (e, id) => {
    let res = await updateWebsiteNotificationStatus(
      id,
      Number(e.target.checked)
    );

    if (res?.data?.status) {
      swal(res?.data?.message, "", "success");
      fetchData();
    } else {
      swal("Error", "", "danger");
    }
  };
  // States of Communication Preview Modal
  const [showPreviewCommunicationModal, setShowPreviewCommunicationModal] =
    React.useState(false);
  const PreviewModalClose = () => setShowPreviewCommunicationModal(false);
  const PreviewModalShow = () => setShowPreviewCommunicationModal(true);

  const handleViewDoc = (id) => {
    let checkNotificationFor = data.find((ele) => +ele.id === +id);
    setnotificationFor(checkNotificationFor?.notification_for_name);
    previewDetdocId((pre) => id);
    PreviewModalShow();
  };

  //  handleSearchValue

  const handleSearchValue = (e) => {
    if (!ALLOWED_CHARACTERS_REGEX.test(e.target.value)) {
      setError({ ...Error, titleError: "You can't use special Character" });
    } else {
      setError({ ...Error, titleError: "" });
    }
    setsearchByTitle(e.target.value);
    debounceSearch(e.target.value);
  };

  const debounceSearch = (value) => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    debounceTimeout.current = setTimeout(async () => {
      let formData = new FormData();
      formData.append("title", value);
      await fetchData(formData);
    }, 500);
  };

  // date range

  const handleApply = async (event, picker) => {
    const formattedStartDate = picker.startDate.format("YYYY-MM-DD");
    const formattedEndDate = picker.endDate.format("YYYY-MM-DD");

    setDateRange({
      ...dateRange,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    });

    let formData = new FormData();
    formData.append("title", searchByTitle);
    formData.append("start_date", formattedStartDate);
    formData.append("end_date", formattedEndDate);
    await fetchData(formData);
  };

  React.useEffect(() => {}, [dateRange]);

  React.useEffect(() => {
    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, []);
  return (
    <>
      <PageTitle
        activeMenu="Website Notification"
        motherMenu="Communication"
        Button={Communication}
        fetchData={fetchData}
      />
      <div className="col-12">
        <div className="card">
          <CardHeader
            handleSearchValue={handleSearchValue}
            searchByTitle={searchByTitle}
            setDateRange={setDateRange}
            dateRange={dateRange}
            handleApply={handleApply}
            Error={Error}
          />
          <div className="card-body">
            {isLoading ? (
              <>
                <div className="text-center">
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="w-100 table-responsive">
                  <div id="example_wrapper" className="dataTables_wrapper">
                    <form>
                      <table id="example" className="display w-100 dataTable">
                        <thead>
                          <tr>
                            <th>S.No</th>
                            <th>Notification Title</th>
                            <th>Message</th>
                            <th>Document</th>
                            <th>URL</th>
                            <th>Status</th>
                            <th>Created On</th>
                            <th>View</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data?.length > 0 ? (
                            data?.map((item, index) => (
                              <tr key={index}>
                                <td>{(currentpage - 1) * 10 + index + 1}</td>

                                <td
                                  className="text-wrap"
                                  style={{
                                    maxWidth: "200px",
                                    wordBreak: "break-word",
                                    overflowWrap: "break-word",
                                  }}
                                >
                                  {item.title.length > 70
                                    ? item.title.substring(0, 70) + "..."
                                    : item.title}
                                </td>
                                <td
                                  className="text-wrap"
                                  style={{ maxWidth: "200px" }}
                                >
                                  {item.message.length > 50
                                    ? item.message.substring(0, 50) + "..."
                                    : item.message}
                                </td>
                                <td>
                                  {item?.attachment_url ? (
                                    formatOFAttachmentRegex.test(
                                      item?.attachment_url
                                    ) ? (
                                      <a
                                        href={item?.attachment_url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        View PDF
                                      </a>
                                    ) : (
                                      <img
                                        src={item?.attachment_url}
                                        alt={item?.department}
                                        style={{
                                          width: "50px",
                                          height: "auto",
                                        }}
                                        onClick={() =>
                                          window.open(
                                            item?.attachment_url,
                                            "_blank"
                                          )
                                        }
                                      />
                                    )
                                  ) : (
                                    "N/A"
                                  )}
                                </td>
                                <td>
                                  {/* {console.log(item)} */}
                                  {item?.url ? (
                                    <a href={`${item?.url}`} target="blank">
                                      URL
                                    </a>
                                  ) : (
                                    "N/A"
                                  )}
                                </td>
                                <td>
                                  <Form>
                                    <Form.Check
                                      type="switch"
                                      defaultChecked={Boolean(
                                        +item.notification_status
                                      )}
                                      id="custom-switch"
                                      onClick={(e) => handleStatus(e, item.id)}
                                    />
                                  </Form>
                                </td>

                                <td>
                                  {new Date(item.created_at).toLocaleDateString(
                                    "en-us",
                                    {
                                      month: "short",
                                      day: "numeric",
                                      year: "numeric",
                                      hour: "numeric",
                                      minute: "numeric",
                                      second: "numeric",
                                    }
                                  )}
                                </td>

                                <td>
                                  <i
                                    onClick={() => handleViewDoc(item.id)}
                                    className="bi bi-eye-fill text-primary"
                                  ></i>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="6" className="text-center">
                                Data is not available
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      <div className="d-flex justify-content-center">
                        {/* <Nav>{pag("", true, "", true, false)}</Nav> */}
                        <PaginationComp
                          size={totalPages}
                          setCurrentPage={setCurrentPage}
                          currentpage={currentpage}
                          gutter={true}
                          variant={""}
                          bg={true}
                          circle={true}
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <PreviewCommunicationModal
        id={previewDocId}
        PreviewModalClose={PreviewModalClose}
        showPreviewCommunicationModal={showPreviewCommunicationModal}
        notificationFor={notificationFor}
      />
    </>
  );
}

export default WebsiteNotification;
