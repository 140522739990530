import React from "react";

/// Image
import profile from "../../../images/profile/userIcon.png";
import { Dropdown } from "react-bootstrap";
import LogoutPage from "./Logout";
import Profile from "./Profile";

const Header = ({ onNote }) => {
  var path = window.location.pathname.split("/");
  var name = path[path.length - 1].split("-");
  const loginData = JSON.parse(localStorage.getItem("login_Details"));
  const userType = localStorage.getItem('user_type');
 
  var filterName = name.length >= 3 ? name.filter((n, i) => i >= 0) : name;

  var finalName = filterName.includes("app")
    ? filterName.filter((f) => f !== "app")
    : filterName.includes("ui")
      ? filterName.filter((f) => f !== "ui")
      : filterName.includes("uc")
        ? filterName.filter((f) => f !== "uc")
        : filterName.includes("basic")
          ? filterName.filter((f) => f !== "basic")
          : filterName.includes("jquery")
            ? filterName.filter((f) => f !== "jquery")
            : filterName.includes("table")
              ? filterName.filter((f) => f !== "table")
              : filterName.includes("page")
                ? filterName.filter((f) => f !== "page")
                : filterName.includes("email")
                  ? filterName.filter((f) => f !== "email")
                  : filterName.includes("ecom")
                    ? filterName.filter((f) => f !== "ecom")
                    : filterName.includes("chart")
                      ? filterName.filter((f) => f !== "chart")
                      : filterName.includes("editor")
                        ? filterName.filter((f) => f !== "editor")
                        : filterName;

  // const finalNameString = finalName.join(" ");
  // const translatedName = grabTheValue(finalNameString);
  return (
    <div className="header border-bottom">
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">
              <div
                className="dashboard_bar"
                style={{ textTransform: "capitalize" }}
              >
              </div>
            </div>
            <ul className="navbar-nav header-right main-notification">

              <Dropdown as="li" className="nav-item dropdown header-profile">
                <Dropdown.Toggle
                  variant=""
                  as="a"
                  className="nav-link i-false c-pointer"
                  role="button"
                  data-toggle="dropdown"
                >
                  <img src={profile} width={20} alt="" />

                </Dropdown.Toggle>
                <Dropdown.Menu
                  align="right"
                  className="mt-3 dropdown-menu dropdown-menu-end"
                >

                  <LogoutPage />
                  {loginData?.type === "user" || userType === "user" ? <Profile /> : ""}
                  
                </Dropdown.Menu>
              </Dropdown>

            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
