import React from "react";
import "../eventCalendar/EventCalendar.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import PageTitle from "../../../jsx/layouts/PageTitle";
import EventNotificationModal from "./EventCalendarModal";
import swal from "sweetalert";
import { FormControl, InputGroup, Nav, Pagination } from "react-bootstrap";
import { Link } from "react-router-dom";
import PreviewEventCalendarModal from "./PreviewEventCalendarModal";
import {
  eventCalendarTypeList,
  getAllEventCalendarData,
  updateEventCalendarStatus,
} from "../../../services/adminApiService/eventCalendarApis/eventCalendarApis";
import { CiFilter, CiSearch } from "react-icons/ci";
import PaginationComp from "../../../common/ui/view/pagination/PaginationComp";

export const formatOFTicketRegex = /.(jpe?g|png)$/i;
const allowedCharsRegex = /^[a-zA-Z0-9?()&'\-,.\s]*$/;

///
const filterForm = {
  type_id: "",
};
function EventCalendar() {
  const [hoveredItemId, setHoveredItemId] = React.useState(null);
  const [data, setData] = React.useState([]);
  const [showWebsiteModal, setShowWebsiteModal] = React.useState(false);
  const [PreviewEventModal, setPreviewEventModal] = React.useState(false);
  const PreviewModalClose = () => setPreviewEventModal(false);
  const PreviewModalShow = () => setPreviewEventModal(true);
  const [eventDocPreviewID, seteventDocPreviewID] = React.useState(null);
  const [filterFormValues, setfilterFormValues] = React.useState(filterForm);
  const debounceRef = React.useRef(null);
  const [searchValue, setsearchValue] = React.useState("");
  const [toggleSearchAndFilter, settoggleSearchAndFilter] =
    React.useState("search");
  const [totalPages, setTotalPages] = React.useState(null); //total pages
  const [currentpage, setCurrentPage] = React.useState(1); //current pages
  const [eventTypeList, seteventTypeList] = React.useState([]);

  const handleStatus = async (e, id) => {
    let res = await updateEventCalendarStatus(id, Number(e.target.checked));
    if (res?.data?.status) {
      swal(res?.data?.message, "", "success");
    } else {
      swal("Error", "", "danger");
    }
  };

  // VIEW DOCUMENT
  const handleViewDoc = (id) => {
    seteventDocPreviewID(id);
    PreviewModalShow();
  };
  // FETCH LISTING DATA
  async function fetchData({ currentpage, searchValue, type_id }) {
    try {
      let obj = {};
      if (currentpage) {
        obj.page = currentpage;
      }
      if (searchValue) {
        obj.searchValue = searchValue;
      }
      if (type_id) {
        obj.type_id = type_id;
      }
      let res = await getAllEventCalendarData(obj);
      setTotalPages(res?.data?.data?.last_page);
      let data = res?.data?.data?.data?.map((ele) => ele);
      setData(data);
    } catch (error) {
      console.log(error.message);
    }
  }
  React.useEffect(() => {
    fetchData({ currentpage });
  }, [currentpage]);

  const handleCloseWebsiteModal = () => setShowWebsiteModal(false);
  const handleShowWebsiteModal = () => setShowWebsiteModal(true);

  const handleChangeFilterValues = (e) => {
    const { name, value } = e.target;
    setfilterFormValues({ ...filterFormValues, [name]: value });
  };

  const resetFilter = () => {
    setfilterFormValues(filterForm);
    fetchData({ currentpage });
  };

  //handlr search value
  const handleSearchValue = (e) => {
    // if (!e.target.value.trim()) return;
    let value = e.target.value.trim();
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }

    debounceRef.current = setTimeout(() => {
      if (value) {
        setsearchValue(value);
        fetchData({ currentpage: 1, searchValue: value });
        setCurrentPage(1);
      } else {
        fetchData({ currentpage });
      }
    }, 500);
  };

  const handleFilterFormValues = (e) => {
    // console.log(filterFormValues);
    e.preventDefault();
    let checkAtleastOneIstruthy = Object.values(filterFormValues).some((ele) =>
      Boolean(ele)
    );

    if (checkAtleastOneIstruthy) {
      fetchData({ currentpage, ...filterFormValues });
    } else {
      return;
    }
  };

  //handle toggle for filter and search
  function handleToggle(value) {
    if (value === toggleSearchAndFilter) {
      return;
    }
    settoggleSearchAndFilter(value);
  }

  const eventListHandler = async () => {
    try {
      const response = await eventCalendarTypeList();
      seteventTypeList(response?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  React.useEffect(() => {
    eventListHandler();
  }, []);
  return (
    <>
      <PageTitle
        activeMenu={"Event Management"}
        motherMenu={"Events"}
        Button={() => (
          <Button onClick={handleShowWebsiteModal}>Add Event</Button>
        )}
      />

      <div className="card">
        <div className="card-body">
          <div className="row mb-3">
            <form onSubmit={handleFilterFormValues}>
              <div className="row mb-3">
                {toggleSearchAndFilter === "filter" ? (
                  <>
                    <div className="col-lg-2">
                      <label>Select your type</label>
                      <select
                        className={`form-control`}
                        name="type_id"
                        onChange={handleChangeFilterValues}
                        value={filterFormValues.type_id}
                      >
                        <option value={""}>Select type</option>

                        {eventTypeList.map((data) => {
                          return (
                            <>
                              <option value={data?.id}>{data?.name}</option>
                            </>
                          );
                        })}
                      </select>
                    </div>

                    <div className="col-lg-1 d-flex align-items-end">
                      <Button type="submit">Search</Button>
                    </div>
                    <div className="col-lg-1 d-flex align-items-end">
                      <Button onClick={resetFilter}>Reset</Button>
                    </div>
                    <div className="col-lg-7"></div>
                  </>
                ) : (
                  <>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <label>Search </label>
                        <InputGroup>
                          <FormControl
                            type="text"
                            className="form-control input-default"
                            placeholder="Search"
                            onChange={handleSearchValue}
                          />
                          {/* <InputGroup.Text onClick={() => {}}>
                            <FaSearch />
                          </InputGroup.Text> */}
                        </InputGroup>
                      </div>
                    </div>
                    <div className="col-lg-7"></div>
                  </>
                )}

                {/* Icons for Filter and Search */}
                <div className="col-lg-1 d-flex align-items-end">
                  <div className="d-flex align-items-center p-2 gap-2">
                    <CiSearch
                      size={30}
                      onClick={() => handleToggle("search")}
                      style={{
                        cursor: "pointer",
                        color:
                          toggleSearchAndFilter === "search"
                            ? "var(--primary)"
                            : "",
                      }}
                    />
                    <CiFilter
                      size={30}
                      onClick={() => handleToggle("filter")}
                      style={{
                        cursor: "pointer",
                        color:
                          toggleSearchAndFilter === "filter"
                            ? "var(--primary)"
                            : "",
                      }}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="e-tender-table-content">
        <div className="table2" id="jharkhand-e-tender-table">
          <div
            className={`${`E-tender-fopTableHeader`} position-relative py-4 body-text`}
          >
            <div>S. No</div>
            <div>Title</div>
            <div>Description</div>
            {/* <div>URL</div> */}
            <div>Document</div>
            <div>Created On</div>
            <div>Start/End Date</div>
            <div>Enable/Disable</div>
            <div>View/Edit</div>
          </div>
          <div className="bodyContainer">
            {data?.length > 0 ? (
              data?.map((item, index) => (
                <div
                  className={`E-tender-fopDivContainer ${
                    hoveredItemId === item.id ? "hovered" : ""
                  }`}
                  key={index}
                  onMouseEnter={() => setHoveredItemId(item.id)}
                  onMouseLeave={() => setHoveredItemId(null)}
                >
                  <td>{(currentpage - 1) * 10 + index + 1}</td>

                  <div
                    className="text-wrap"
                    style={{
                      maxWidth: "200px",
                      wordBreak: "break-word",
                      overflowWrap: "break-word",
                    }}
                  >
                    {item.title.length > 70
                      ? item.title.substring(0, 70) + "..."
                      : item.title}
                  </div>
                  <div
                    className="text-wrap m-4"
                    style={{
                      maxWidth: "200px",
                      wordBreak: "break-word",
                      overflowWrap: "break-word",
                    }}
                  >
                    {item.description.length > 70
                      ? item.description.substring(0, 70) + "..."
                      : item.description}
                  </div>

                  {/* <div className="m-4">
                    <Link>URL</Link>
                  </div> */}
                  <div className="m-4">
                    {item?.attachement_storage_url ? (
                      formatOFTicketRegex.test(
                        item?.attachement_storage_url
                      ) ? (
                        <img
                          src={item?.attachement_storage_url}
                          alt={item?.department}
                          style={{ width: "100px", height: "auto" }}
                          onClick={() =>
                            window.open(item?.attachement_storage_url, "_blank")
                          }
                        />
                      ) : (
                        <a
                          href={item?.attachement_storage_url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View PDF
                        </a>
                      )
                    ) : (
                      "N/A"
                    )}
                  </div>
                  <div className="m-4">
                    {new Date(item.created_at).toLocaleDateString("en-us", {
                      month: "short",
                      day: "numeric",
                      year: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                      second: "numeric",
                    })}
                  </div>
                  {/* start_date_time end_date_time */}
                  <div className="m-4">
                    {item?.start_date_time
                      ? new Date(item.start_date_time).toLocaleString("en-US", {
                          month: "short",
                          day: "numeric",
                          year: "numeric",
                        })
                      : "N/A"}
                    {" - "}
                    {item?.end_date_time
                      ? new Date(item.end_date_time).toLocaleString("en-US", {
                          month: "short",
                          day: "numeric",
                          year: "numeric",
                        })
                      : "N/A"}
                  </div>

                  <div className="m-4">
                    <td>
                      <Form>
                        <Form.Check
                          defaultChecked={Boolean(+item.status)}
                          onChange={(e) => handleStatus(e, item.id)}
                          type="switch"
                          id="custom-switch"
                        />
                      </Form>
                    </td>
                  </div>
                  <td>
                    <i
                      onClick={() => handleViewDoc(item.id)}
                      className="bi bi-eye-fill text-primary"
                    ></i>
                  </td>
                </div>
              ))
            ) : (
              <div className="d-flex justify-content-center">
                Data is not available
              </div>
            )}

            <div className="d-flex justify-content-center">
              <PaginationComp
                size={totalPages}
                setCurrentPage={setCurrentPage}
                currentpage={currentpage}
                gutter={true}
                variant={""}
                bg={true}
                circle={true}
              />
            </div>
          </div>
        </div>
      </div>
      {eventDocPreviewID && (
        <PreviewEventCalendarModal
          PreviewModalClose={PreviewModalClose}
          setPreviewEventModal={setPreviewEventModal}
          PreviewEventModal={PreviewEventModal}
          eventDocPreviewID={eventDocPreviewID}
          seteventDocPreviewID={seteventDocPreviewID}
          fetchData={fetchData}
          currentpage={currentpage}
        />
      )}
      <EventNotificationModal
        fetchData={fetchData}
        handleCloseWebsiteModal={handleCloseWebsiteModal}
        showWebsiteModal={showWebsiteModal}
        currentpage={currentpage}
      />
    </>
  );
}

export default EventCalendar;
