import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import {
  editEventCalendar,
  eventCalendarTypeList,
  viewEventCalendarDetails,
} from "../../../services/adminApiService/eventCalendarApis/eventCalendarApis";
import { formatDate } from "./EventCalendarModal";
import swal from "sweetalert";

// const ALLOWED_CHARACTERS_REGEX = /^[a-zA-Z0-9\s\()\.\,\-\!\:\&]*$/; //sepcial charatcter
const ALLOWED_CHARACTERS_REGEX = /^.*$/; //sepcial charatcter
function EditEventCalendarModal({
  PreviewModalClose,
  PreviewEventModal,
  eventDocPreviewID,
  fetchData,
  currentpage,
}) {
  const [eventTypeList, seteventTypeList] = React.useState([]);

  const [formData, setFormData] = useState({
    title: "",
    description: "",
    type_id: "",
    organized_by: "",
    start_date_time: "",
    end_date_time: "",
    location: "",
    remarks: "",
  });
  const [errors, setErrors] = useState({});
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [fileUrl, setFileUrl] = React.useState(null);
  const fileInputRef = React.useRef(null);

  const eventListHandler = async () => {
    try {
      const response = await eventCalendarTypeList();
      seteventTypeList(response?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    eventListHandler();
  }, []);
  useEffect(() => {
    (async () => {
      try {
        let res = await viewEventCalendarDetails(eventDocPreviewID);
        const data = res?.data?.data || {};
        setFormData({
          title: data.title || "",
          description: data.description || "",
          type_id: data.type_id ? data.type_id.toString() : "",
          organized_by: data.organized_by || "",
          start_date_time: data.start_date_time || "",
          end_date_time: data.end_date_time || "",
          location: data.location || "",
          remarks: data.remarks || "",
        });
      } catch (error) {
        console.error(error);
      }
    })();
  }, [eventDocPreviewID]);
  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.title) {
      newErrors.title = "Title is required.";
    } else if (!ALLOWED_CHARACTERS_REGEX.test(formData.title)) {
      newErrors.title = "Title contains invalid characters.";
    }
    if (!formData.description)
      newErrors.description = "Description is required.";
    if (!formData.type_id) newErrors.type_id = "Type is required.";

    // Validate start and end dates based on their presence
    if (formData.end_date_time && !formData.start_date_time) {
      swal(
        "Error",
        "Start date is required when the end date is selected.",
        "error"
      );
      newErrors.start_date_time =
        "Start date is required when the end date is selected.";
    }

    if (formData.start_date_time && formData.end_date_time) {
      const startDate = new Date(formData.start_date_time);
      const endDate = new Date(formData.end_date_time);

      if (startDate > endDate) {
        swal("Error", "Start date must be earlier than the end date.", "error");
        newErrors.start_date_time =
          "Start date must be earlier than the end date.";
        newErrors.end_date_time = "End date must be later than the start date.";
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      const updatedFormData = {
        ...formData,
        start_date_time:
          formData.start_date_time && formatDate(formData.start_date_time),
        end_date_time:
          formData.end_date_time && formatDate(formData.end_date_time),
        attachement: selectedFile && selectedFile,
      };
      const newFormData = new FormData();
      //append the form values in new formdata
      Object.keys(updatedFormData).forEach((key) => {
        if (
          updatedFormData[key] !== undefined &&
          updatedFormData[key] !== null
        ) {
          newFormData.append(`${key}`, updatedFormData[key]);
        }
      });
      newFormData.append("id", eventDocPreviewID);
      try {
        let res = await editEventCalendar(newFormData);
        if (res.data.status && res.data.status !== "error") {
          PreviewModalClose();
          swal(res.data.message, "", "success");
          fetchData({ currentpage });
        } else {
          swal(`${res.data.status}`, "", "error");
        }
      } catch (error) {
        console.error("Error submitting form:", error);
        swal(`${error.message}`, "", "error");
      }
    }
  };

  const handleFilePreview = () => {
    if (fileUrl) {
      window.open(fileUrl, "_blank");
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      const allowedExtensions = /(\.jpg|\.jpeg|\.webp|\.png|\.pdf)$/i;
      const maxFileSize = 2 * 1024 * 1024; // 2 MB

      if (!allowedExtensions.test(file.name)) {
        swal(
          "Error",
          "Invalid file type. Only jpg, jpeg, webp, png, and pdf files are allowed.",
          "error"
        );
        setSelectedFile(null);
        setFileUrl(null);
        return;
      }

      if (file.size > maxFileSize) {
        swal("Error", "File size should be less than 2 MB.", "error");
        setSelectedFile(null);
        setFileUrl(null);
        return;
      }

      // Set valid file
      setSelectedFile(file);
      setFileUrl(URL.createObjectURL(file));
      swal("", "File is selected", "success");
    } else {
      setSelectedFile(null);
      setFileUrl(null);
    }
  };

  return (
    <Modal show={PreviewEventModal} onHide={PreviewModalClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Event Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="add-contact-box">
          <div className="add-contact-content">
            {/* 1st Row */}
            <div className="row">
              <div className="form-group mb-3 col-md-12">
                <label className="text-black font-w500">Title</label>
                <span className="text-danger">*</span>
                <Form.Control
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                  placeholder="Title"
                  isInvalid={!!errors.title}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.title}
                </Form.Control.Feedback>
              </div>
            </div>
            {/* 2nd Row */}
            <div className="row">
              <div className="form-group mb-3 col-md-12">
                <label className="text-black font-w500">Description</label>
                <span className="text-danger">*</span>
                <Form.Control
                  as="textarea"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  placeholder="Description"
                  isInvalid={!!errors.description}
                />
                <Form.Control.Feedback className="validation-text text-danger">
                  {errors.description}
                </Form.Control.Feedback>
              </div>
            </div>

            {/* 3rd row */}
            <div className="row">
              <div className="form-group mb-3 col-md-6">
                <label className="text-black font-w500">Type</label>
                <span className="text-danger">*</span>
                <select
                  required
                  name="type_id"
                  value={formData.type_id}
                  onChange={handleChange}
                  className={`form-control ${
                    errors.type_id ? "is-invalid" : ""
                  }`}
                >
                  <option value="">Select type</option>
                  {eventTypeList.map((data) => {
                    return (
                      <>
                        <option value={data?.id}>{data?.name}</option>
                      </>
                    );
                  })}
                </select>
              </div>

              <div className="form-group mb-3 col-md-6">
                <label className="text-black font-w500">Organized By</label>
                {/* <span className="text-danger">*</span> */}

                <div className="contact-name">
                  <Form.Control
                    name="organized_by"
                    value={formData.organized_by}
                    placeholder="Enter Organization name"
                    onChange={handleChange}
                  />
                </div>

                {/* <span className="validation-text text-danger">
                    {Error.OrganinsationError && Error.OrganinsationError}
                  </span> */}
              </div>
            </div>
            {/* 4th Row */}
            <div className="row">
              <div className="form-group mb-3 col-md-6">
                <label className="text-black font-w500">Start Date</label>
                <Form.Control
                  type="datetime-local"
                  name="start_date_time"
                  value={formData.start_date_time}
                  onChange={handleChange}
                  placeholder="Start Date"
                  isInvalid={!!errors.start_date_time}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.start_date_time}
                </Form.Control.Feedback>
              </div>
              <div className="form-group mb-3 col-md-6">
                <label className="text-black font-w500">End Date</label>
                <Form.Control
                  type="datetime-local"
                  name="end_date_time"
                  value={formData.end_date_time}
                  onChange={handleChange}
                  placeholder="End Date"
                  isInvalid={!!errors.end_date_time}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.end_date_time}
                </Form.Control.Feedback>
              </div>
            </div>
            {/* 5th Row */}
            <div className="row">
              <div className="form-group mb-3 col-md-6">
                <label className="text-black font-w500">Location</label>
                <Form.Control
                  name="location"
                  value={formData.location}
                  onChange={handleChange}
                  placeholder="Location"
                  isInvalid={!!errors.location}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.location}
                </Form.Control.Feedback>
              </div>
              <div className="form-group mb-3 col-md-6">
                <label className="text-black font-w500">Remarks</label>
                <Form.Control
                  name="remarks"
                  value={formData.remarks}
                  onChange={handleChange}
                  placeholder="Remarks"
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group mb-3 col-md-12">
                <Form.Group controlId="formFile">
                  <Form.Label className="text-black font-w500">
                    Upload Document
                    {/* <span className="text-danger">*</span> */}
                  </Form.Label>
                  <div className="d-flex align-items-center">
                    <Form.Control
                      ref={fileInputRef}
                      type="file"
                      accept="image/*,application/pdf"
                      onChange={handleFileChange}
                    />
                    <Button
                      variant="primary"
                      className="ms-2"
                      disabled={!selectedFile}
                      onClick={handleFilePreview}
                    >
                      Preview
                    </Button>
                  </div>
                </Form.Group>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={PreviewModalClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Update Event
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EditEventCalendarModal;
