import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import PageTitle from "../../layouts/PageTitle";
import RaiseTicketModal from "./RaiseTicketModal";
import {
  addUserSingleTicketResponse,
  getGrievanceAllUserTicket,
  getSingleUserData,
} from "../../../services/adminApiService/grievanceTicket/grievanceTicketApis";
import PaginationComp from "../../../common/ui/view/pagination/PaginationComp";
import ViewTicket from "../../../admin/admincomponent/ticket/ViewTicket";
import moment from "moment";

// const allowedCharsRegex = /^[a-zA-Z0-9?()&'\-,.\s]*$/;
const allowedCharsRegex = /^.*$/;

//colors
const getStatusColor = (status) => {
  switch (status) {
    case "In_Progress":
      return "#FFA500"; // Orange
    case "Resolved":
      return "#4CAF50"; // Green
    case "Closed":
      return "#808080"; // Gray
    case "Reopened":
      return "#FF4500"; // Dark Orange/Red
    case "New":
      return "#FF8C00"; // Dark Orange/ Dark Orange/Red
    default:
      return "#000000"; // Black as fallback
  }
};

// file extension check regex
export const formatOFTicketRegex = /.(jpe?g|png)$/i;

function UserTicket() {
  const [showTicketModal, setshowTicketModal] = React.useState(false);
  const handleShowTicketModal = () => setshowTicketModal(true); //for open the ticket modal
  const handleCloseTicketModal = () => setshowTicketModal(false); // for close the ticket Modal
  const [currentpage, setCurrentPage] = React.useState(1); //current pages
  const [totalPages, setTotalPages] = React.useState(null); //total pages
  const [uploadedFile, setUploadedFile] = React.useState(null); //grab file
  const [data, setData] = React.useState({});
  const [viewTicketData, setviewTicketData] = React.useState({});
  const [responseText, setResponseText] = React.useState("");
  const [isLoading, setisLoading] = React.useState(false);
  const [error, setError] = useState({});
  const [show, setshow] = React.useState(false);
  const [selectedTickedDeatils, setselectedTickedDeatils] = React.useState({});
  const [currentChatId, setcurrentChatId] = React.useState(null);
  const selectedTicketRef = React.useRef(null);

  //close chat
  const handleCloseChat = () => {
    setviewTicketData({});
  };

  const handleViewTicket = async (index, item) => {
    setshow(true);
    setselectedTickedDeatils(item);
    selectedTicketRef.current = item;

    setcurrentChatId(item?.id);

    let res = await getSingleUserData(item?.id);
    if (res?.data?.status) setviewTicketData(res?.data?.data);
  };
  //SEND RESPONSE
  const handleResponseText = (e) => {
    let { name, value } = e.target;
    value = value.trimStart();
    setResponseText(value);
    if (!allowedCharsRegex.test(value)) {
      setError({
        ...error,
        [name]:
          "Invalid characters detected. Only ? ( ) - ' & . , are allowed.",
      });
    } else {
      setError("");
    }
  };

  const handleSubmitResponse = async (id) => {
    if (!responseText && !uploadedFile) {
      return;
    }

    if (Object.keys(error).length > 0) {
      return;
    }

    // submit the response
    let formData = new FormData();
    formData.append("description", responseText);
    formData.append("id", id);
    uploadedFile && formData.append("attachement", uploadedFile);
    setisLoading(true);
    try {
      let res = await addUserSingleTicketResponse(formData);
      if (res?.data?.status) {
        await handleViewTicket("_", selectedTicketRef.current);

        setResponseText("");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setisLoading(false);
    }
  };

  //fetch tickets
  const fetchAllTicketData = React.useCallback(async (currentpage) => {
    try {
      let res = await getGrievanceAllUserTicket({ currentpage });
      setTotalPages(res?.data?.data?.last_page);
      setData(res?.data?.data);
    } catch (error) {
      console.log(error.message);
    }
  }, []);

  React.useEffect(() => {
    fetchAllTicketData(currentpage);
  }, [currentpage, fetchAllTicketData]);

  // for closing the charbox
  const handleClose = () => setshow(false);

  return (
    <>
      <PageTitle
        activeMenu="Grievence List"
        motherMenu="Grievance-Management"
        Button={() => (
          <Button onClick={handleShowTicketModal}>Raise Ticket</Button>
        )}
      />
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <div className="w-100 table-responsive">
              <div id="example_wrapper" className="dataTables_wrapper">
                <table id="example" className="display w-100 dataTable">
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Ticket No</th>
                      <th>Create At</th>
                      <th>Ticket Category</th>
                      <th>Title</th>
                      <th>Description</th>
                      <th>Status</th>
                      <th>Attachement</th>
                      <th>View Detail</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.data?.length > 0 ? (
                      data?.data?.map((item, index) => (
                        <tr key={index}>
                          <td>{(currentpage - 1) * 10 + index + 1}</td>

                          <td>{item.ticket_unique_id}</td>
                          <td>
                            {moment(item?.created_at).format(
                              "MMM DD, YY hh:mm:ss A"
                            )}{" "}
                            {/* e.g., "Nov 13, 24 04:35:22 PM" */}
                          </td>
                          <td>{item.ticket_category_type}</td>
                          <td
                            className="text-wrap"
                            style={{ maxWidth: "200px" }}
                          >
                            {item.title}
                          </td>
                          <td
                            className="text-wrap"
                            style={{ maxWidth: "200px" }}
                          >
                            {item.description.length > 50
                              ? item.description.substring(0, 50) + "..."
                              : item.description}
                          </td>
                          <td style={{ color: getStatusColor(item.status) }}>
                            {item.status === "In_Progress"
                              ? "In Progress"
                              : item.status}
                          </td>

                          <td>
                            {item?.attachement_storage_url ? (
                              formatOFTicketRegex.test(
                                item?.attachement_storage_url
                              ) ? (
                                <img
                                  src={item?.attachement_storage_url}
                                  alt={item?.department}
                                  style={{ width: "50px", height: "auto" }}
                                  onClick={() =>
                                    window.open(
                                      item?.attachement_storage_url,
                                      "_blank"
                                    )
                                  }
                                />
                              ) : (
                                <a
                                  href={item?.attachement_storage_url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  View PDF
                                </a>
                              )
                            ) : (
                              "N/A"
                            )}
                          </td>

                          <td>
                            <i
                              onClick={() => handleViewTicket(item._id, item)}
                              className="bi bi-eye-fill text-primary"
                            ></i>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="6" className="text-center">
                          Data is not available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <div className="d-flex justify-content-center py-2">
                  <PaginationComp
                    size={totalPages}
                    setCurrentPage={setCurrentPage}
                    currentpage={currentpage}
                    gutter={true}
                    variant={""}
                    bg={true}
                    circle={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-12">
        <div className="card">
          <ViewTicket
            showfor={"user"}
            onChangeFunc={handleResponseText}
            value={responseText}
            handleCloseChat={handleCloseChat}
            viewTicketData={viewTicketData}
            handleSubmitResponse={handleSubmitResponse}
            setUploadedFile={setUploadedFile}
            isLoading={isLoading}
            uploadedFile={uploadedFile}
            handleViewTicket={handleViewTicket}
            error={error}
            show={show}
            handleClose={handleClose}
            // selectedTickedDeatils={selectedTickedDeatils}
            selectedTickedDeatils={
              selectedTickedDeatils || selectedTicketRef.current
            }
            // currentChatId={currentChatId}
            currentChatId={
              selectedTickedDeatils?.id || selectedTicketRef.current?.id
            }
          />
        </div>
      </div>

      <RaiseTicketModal
        fetchAllTicketData={fetchAllTicketData}
        showTicketModal={showTicketModal}
        handleCloseTicketModal={handleCloseTicketModal}
        currentpage={currentpage}
      />
    </>
  );
}

export default UserTicket;
