import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import {
  addEventCalendar,
  eventCalendarTypeList,
} from "../../../services/adminApiService/eventCalendarApis/eventCalendarApis.js";
import swal from "sweetalert";

// Validation of special character
// const ALLOWED_CHARACTERS_REGEX = /^[a-zA-Z0-9\s\()\.\,\-\!\:\&]*$/;
const ALLOWED_CHARACTERS_REGEX = /^.*$/;

const initialFormData = {
  title: "",
  description: "",
  type_id: "",
  organized_by: "",
  start_date_time: "",
  end_date_time: "",
  location: "",
  remarks: "",
};

export const formatDate = (date) => {
  const d = new Date(date);
  const year = d.getFullYear();
  const month = String(d.getMonth() + 1).padStart(2, "0");
  const day = String(d.getDate()).padStart(2, "0");
  const hours = String(d.getHours()).padStart(2, "0");
  const minutes = String(d.getMinutes()).padStart(2, "0");
  return `${year}-${month}-${day} ${hours}:${minutes}`;
};

function EventNotificationModal({
  showWebsiteModal,
  handleCloseWebsiteModal,
  fetchData,
  currentpage,
}) {
  const [formData, setFormData] = React.useState(initialFormData);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [fileUrl, setFileUrl] = React.useState(null);
  const [eventTypeList, seteventTypeList] = React.useState([]);
  const [Error, setError] = React.useState({
    titleError: "",
    descriptionError: "",
    locationError: "",
    remarksError: "",
    OrganinsationError: "",
  });
  const fileInputRef = React.useRef(null);

  const handleReset = () => {
    setFormData(initialFormData);
    setSelectedFile(null);
    setFileUrl(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (Object.values(Error).some(Boolean)) {
      swal("Validation failed!", "", "error");
      return;
    }

    const newFormData = new FormData();
    const updatedFormData = {
      ...formData,
      start_date_time:
        formData.start_date_time && formatDate(formData.start_date_time),
      end_date_time:
        formData.end_date_time && formatDate(formData.end_date_time),
      attachement: selectedFile,
    };
    // Append each key-value pair from updatedFormData to newFormData
    Object.keys(updatedFormData).forEach((key) => {
      if (updatedFormData[key] !== undefined && updatedFormData[key] !== null) {
        newFormData.append(`${key}`, updatedFormData[key]);
      }
    });
    try {
      let res = await addEventCalendar(newFormData);
      if (res.data.status && res.data.status !== "error") {
        handleCloseWebsiteModal();
        swal("Event Created!", "", "success");
        fetchData({ currentpage });
        handleReset();
      } else {
        swal(`${res.data.status}`, "", "error");
      }
    } catch (error) {
      swal(`${error}`, "", "error");
    }
  };

  // Handle input changes
  const handleChangeInputValue = (e) => {
    let { name, value } = e.target;
    value = value.trimStart();
    if (name === "title") {
      if (!ALLOWED_CHARACTERS_REGEX.test(value)) {
        setError({ ...Error, titleError: "You can't use special Character" });
        return;
      } else {
        setError({ ...Error, titleError: "" });
      }
    }

    if (name === "start_date_time") {
      if (
        formData.end_date_time &&
        new Date(value) > new Date(formData.end_date_time)
      ) {
        swal("Error", "Start date must be earlier than the end date.", "error");
        return;
      }
    }

    if (name === "end_date_time") {
      if (
        formData.start_date_time &&
        new Date(value) < new Date(formData.start_date_time)
      ) {
        swal("Error", "End date must be later than the start date.", "error");
        return;
      }
    }
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      const allowedExtensions = /(\.jpg|\.jpeg|\.webp|\.png|\.pdf)$/i;
      const maxFileSize = 2 * 1024 * 1024; // 2 MB

      if (!allowedExtensions.test(file.name)) {
        swal(
          "Error",
          "Invalid file type. Only jpg, jpeg, webp, png, and pdf files are allowed.",
          "error"
        );
        setSelectedFile(null);
        setFileUrl(null);
        return;
      }

      if (file.size > maxFileSize) {
        swal("Error", "File size should be less than 2 MB.", "error");
        setSelectedFile(null);
        setFileUrl(null);
        return;
      }

      // Set valid file
      setSelectedFile(file);
      setFileUrl(URL.createObjectURL(file));
      swal("", "File is selected", "success");
    } else {
      setSelectedFile(null);
      setFileUrl(null);
    }
  };

  const handleFilePreview = () => {
    if (fileUrl) {
      window.open(fileUrl, "_blank");
    }
  };

  const eventListHandler = async () => {
    try {
      const response = await eventCalendarTypeList();
      seteventTypeList(response?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    eventListHandler();
  }, []);
  return (
    <Modal show={showWebsiteModal} onHide={handleCloseWebsiteModal}>
      <form onSubmit={handleSubmit}>
        <div className="modal-header">
          <h4 className="modal-title fs-20">Create Event</h4>
          <button
            type="button"
            className="btn-close"
            onClick={handleCloseWebsiteModal}
          >
            <span></span>
          </button>
        </div>
        <div className="modal-body">
          <div className="add-contact-box">
            <div className="add-contact-content">
              {/* 1st Row */}
              <div className="row">
                <div className="form-group mb-3 col-md-12">
                  <label className="text-black font-w500">
                    Title <span className="text-danger">*</span>
                  </label>
                  <div className="contact-name">
                    <Form.Control
                      required
                      name="title"
                      value={formData.title}
                      placeholder="Enter Title"
                      onChange={handleChangeInputValue}
                    />
                  </div>

                  <span className="validation-text text-danger">
                    {Error.titleError && Error.titleError}
                  </span>
                </div>
              </div>
              {/* 2nd Row */}
              <div className="row">
                <div className="form-group mb-3 col-md-12">
                  <label className="text-black font-w500">
                    Description <span className="text-danger">*</span>
                  </label>
                  <div className="contact-name">
                    <Form.Control
                      required
                      name="description"
                      as="textarea"
                      placeholder="Description"
                      onChange={handleChangeInputValue}
                      value={formData.description}
                    />
                  </div>

                  <span className="validation-text text-danger">
                    {Error.descriptionError && Error.descriptionError}
                  </span>
                </div>
              </div>
              {/* 3rd row */}
              <div className="row">
                <div className="form-group mb-3 col-md-6">
                  <label className="text-black font-w500">Type</label>
                  <span className="text-danger">*</span>
                  <select
                    required
                    name="type_id"
                    className="form-control"
                    value={formData.type}
                    onChange={handleChangeInputValue}
                  >
                    <option value={""}>Select type</option>

                    {eventTypeList.map((data) => {
                      return (
                        <>
                          <option value={data?.id}>{data?.name}</option>
                        </>
                      );
                    })}
                  </select>
                </div>

                <div className="form-group mb-3 col-md-6">
                  <label className="text-black font-w500">Organized By</label>
                  {/* <span className="text-danger">*</span> */}

                  <div className="contact-name">
                    <Form.Control
                      name="organized_by"
                      value={formData.organized_by}
                      placeholder="Enter Organization name"
                      onChange={handleChangeInputValue}
                    />
                  </div>

                  {/* <span className="validation-text text-danger">
                    {Error.OrganinsationError && Error.OrganinsationError}
                  </span> */}
                </div>
              </div>
              {/* 4th row Start Date & End Date */}
              <div className="row">
                <div className="form-group mb-3 col-md-6">
                  <label className="text-black font-w500">Start Date</label>
                  {/* <span className="text-danger">*</span> */}
                  <Form.Group controlId="startDate">
                    <Form.Control
                      type="datetime-local"
                      // type="date"
                      name="start_date_time"
                      value={formData.start_date_time}
                      onChange={handleChangeInputValue}
                    />
                  </Form.Group>
                </div>

                <div className="form-group mb-3 col-md-6">
                  <label className="text-black font-w500">End Date</label>
                  {/* <span className="text-danger">*</span> */}
                  <Form.Group controlId="endDate">
                    <Form.Control
                      // type="date"
                      type="datetime-local"
                      name="end_date_time"
                      value={formData.end_date_time}
                      onChange={handleChangeInputValue}
                    />
                  </Form.Group>
                </div>
              </div>

              {/* 5th row */}
              <div className="row">
                <div className="form-group mb-3 col-md-6">
                  <label className="text-black font-w500">
                    {/* Location <span className="text-danger">*</span> */}
                  </label>
                  <div className="contact-name">
                    <Form.Control
                      name="location"
                      placeholder="Location"
                      value={formData.location}
                      onChange={handleChangeInputValue}
                    />
                  </div>

                  {/* <span className="validation-text text-danger">
                    {Error.locationError && Error.locationError}
                  </span> */}
                </div>
                <div className="form-group mb-3 col-md-6">
                  <label className="text-black font-w500">
                    {/* Remarks <span className="text-danger">*</span> */}
                  </label>
                  <div className="contact-name">
                    <Form.Control
                      name="remarks"
                      placeholder="Remarks"
                      value={formData.remarks}
                      onChange={handleChangeInputValue}
                    />
                  </div>
                  {/* <span className="validation-text text-danger">
                    {Error.remarksError && Error.remarksError}
                  </span> */}
                </div>
              </div>

              <div className="row">
                <div className="form-group mb-3 col-md-12">
                  <Form.Group controlId="formFile">
                    <Form.Label className="text-black font-w500">
                      Upload Document
                      {/* <span className="text-danger">*</span> */}
                    </Form.Label>
                    <div className="d-flex align-items-center">
                      <Form.Control
                        ref={fileInputRef}
                        type="file"
                        accept="image/*,application/pdf"
                        onChange={handleFileChange}
                      />
                      <Button
                        variant="primary"
                        className="ms-2"
                        disabled={!selectedFile}
                        onClick={handleFilePreview}
                      >
                        Preview
                      </Button>
                    </div>
                  </Form.Group>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer d-flex justify-content-between">
          <div className="d-flex justify-content-end w-100">
            <button
              type="button"
              className="btn btn-secondary mx-2"
              onClick={handleReset}
              // onClick={handleCloseWebsiteModal}
            >
              Reset
            </button>
            <Button type="submit" className="btn btn-primary mx-2">
              Raise
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
}

export default React.memo(EventNotificationModal);
