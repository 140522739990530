import React from "react";
import Swal from "sweetalert2";
import swal from "sweetalert";
import PageTitle from "../../../jsx/layouts/PageTitle";
import { CiFilter, CiSearch } from "react-icons/ci";

import {
  addSingleTicketResponse,
  getGrievanceAllTicket,
  getSingleData,
  updateStausAndPriority,
} from "../../../services/adminApiService/grievanceTicket/grievanceTicketApis";
import ViewTicket from "./ViewTicket";
import PaginationComp from "../../../common/ui/view/pagination/PaginationComp";
import { Button, FormControl, InputGroup, Nav } from "react-bootstrap";
import moment from "moment";

export const formatOFTicketRegex = /.(jpe?g|png)$/i;
// const allowedCharsRegex = /^[a-zA-Z0-9?()&'\-,.\s]*$/;
const allowedCharsRegex = /^.*$/;

//colors
const getStatusColor = (status) => {
  switch (status) {
    case "In_Progress":
      return "#FFA500"; // Orange
    case "Resolved":
      return "#4CAF50"; // Green
    case "Closed":
      return "#808080"; // Gray
    case "Reopened":
      return "#FF4500"; // Dark Orange/Red
    case "Low":
      return "#00BFFF"; // Light Blue
    case "Medium":
      return "#FFD700"; // Gold
    case "High":
      return "#FF8C00"; // Dark Orange
    case "Critical":
      return "#FF0000"; // Red
    case "New":
      return "#FF0000"; // Red
    default:
      return "#000000"; // Black as fallback
  }
};

const filterForm = {
  priority: "",
  status: "",
};
function Ticket() {
  //filter states
  const [filterFormValues, setfilterFormValues] = React.useState(filterForm);
  const [toggleSearchAndFilter, settoggleSearchAndFilter] =
    React.useState("filter");
  const [searchValue, setsearchValue] = React.useState("");
  // filter states end
  const [currentpage, setCurrentPage] = React.useState(1); //current pages
  const [totalPages, setTotalPages] = React.useState(null); //total pages
  const [uploadedFile, setUploadedFile] = React.useState(null); //grab file
  const [viewTicketData, setviewTicketData] = React.useState({});
  const [data, setData] = React.useState({});
  const [responseText, setResponseText] = React.useState("");
  const [currentChatId, setcurrentChatId] = React.useState(null);
  const [isLoading, setisLoading] = React.useState(false);
  const [error, setError] = React.useState({ replyCommentBox: "" });
  const [show, setshow] = React.useState(false);
  const [selectedTickedDeatils, setselectedTickedDeatils] = React.useState({});
  const debounceRef = React.useRef(null);
  const selectedTicketRef = React.useRef(null);
  //fetch tickets
  const fetchAllTicketData = React.useCallback(async (currentpage, payload) => {
    try {
      let res = await getGrievanceAllTicket({ currentpage, ...payload });
      setTotalPages(res?.data?.data?.last_page);
      setData(res?.data?.data);
    } catch (error) {
      console.log(error.message);
    }
  }, []);
  React.useEffect(() => {
    fetchAllTicketData(currentpage);
  }, [currentpage, fetchAllTicketData]);
  //   handle View Ticket
  const handleViewTicket = async (index, item) => {
    setselectedTickedDeatils(item);
    selectedTicketRef.current = item;
    setcurrentChatId(item?.id);
    setshow(true);
    let res = await getSingleData(item?.id);
    if (res?.data?.status) setviewTicketData(res?.data?.data);
  };

  const handleResponseText = (e) => {
    let { name, value } = e.target;
    value = value.trimStart();
    setResponseText(e.target.value);
    if (!allowedCharsRegex.test(value)) {
      setError({
        ...error,
        [name]:
          "Invalid characters detected. Only ? ( ) - ' & . , are allowed.",
      });
    } else {
      setError("");
    }
  };
  const handleSubmitResponse = async (id) => {
    if (!responseText && !uploadedFile) {
      return;
    }

    if (Object.keys(error).length > 0) {
      return;
    }
    // submit the response

    let formData = new FormData();
    formData.append("description", responseText);
    uploadedFile && formData.append("attachement", uploadedFile);
    formData.append("id", id);
    setisLoading(true);
    try {
      let res = await addSingleTicketResponse(formData);
      if (res?.data?.status) {
        await handleViewTicket("_", selectedTicketRef.current);
        setResponseText("");
        setUploadedFile(null);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setisLoading(false);
    }
  };

  //handle status
  const handleStatusOfTicket = (e, { id }) => {
    if (!e.target.value) {
      return;
    }
    Swal.fire({
      title: "",
      text: "Are you sure you want to update the status?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, update it!",
      cancelButtonText: "No, cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let res = await updateStausAndPriority({
          id,
          status: e.target.value,
        });

        if (res?.data?.status) {
          swal(res?.data?.message, {
            icon: "success",
          });
          fetchAllTicketData(currentpage);
        } else {
          swal(res?.data?.message, {
            icon: "success",
          });
        }
      } else {
        swal("Your Status is not upated", {
          icon: "success",
        });
      }
    });
  };

  //handle priorty level
  const handlePriotityOfTicket = (e, { id }) => {
    if (!e.target.value) {
      return;
    }
    Swal.fire({
      title: "",
      text: "Are you sure you want to update the priority?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, update it!",
      cancelButtonText: "No, cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (result.isConfirmed) {
          let res = await updateStausAndPriority({
            id,
            priority: e.target.value,
          });

          if (res?.data?.status) {
            swal(res?.data?.message, {
              icon: "success",
            });
            fetchAllTicketData(currentpage);
          } else {
            swal(res?.data?.message, {
              icon: "success",
            });
          }
        }
      } else {
        swal("Your Priority is not upated", {
          icon: "success",
        });
      }
    });
  };

  //handle CHat Dialog
  const handleClose = () => setshow(false);

  ///handle Filters of admin tickets
  const handleChangeFilterValues = (e) => {
    const { name, value } = e.target;
    setfilterFormValues({ ...filterFormValues, [name]: value });
  };
  const handleFilterFormValues = (e) => {
    e.preventDefault();
    let checkAtleastOneIstruthy = Object.values(filterFormValues).some((ele) =>
      Boolean(ele)
    );
    if (checkAtleastOneIstruthy) {
      fetchAllTicketData(currentpage, filterFormValues);
    } else {
      return;
    }
  };

  const resetFilter = () => {
    setfilterFormValues(filterForm);
    fetchAllTicketData(currentpage);
  };

  //handlr search value
  const handleSearchValue = (e) => {
    // if (!e.target.value.trim()) return;
    let value = e.target.value.trim();
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }

    debounceRef.current = setTimeout(() => {
      if (value) {
        setsearchValue(value);

        fetchAllTicketData(currentpage, { search: value });
      } else {
        fetchAllTicketData(currentpage);
      }
    }, 500);
  };
  //handle toggle for filter and search
  function handleToggle(value) {
    if (value === toggleSearchAndFilter) {
      return;
    }
    settoggleSearchAndFilter(value);
  }
  return (
    <>
      <PageTitle
        activeMenu="Grievence List"
        motherMenu="Grievance-Management"
      />

      <div className="card">
        <div className="card-body">
          <div className="row mb-3">
            <form onSubmit={handleFilterFormValues}>
              <div className="row mb-3">
                {toggleSearchAndFilter === "filter" ? (
                  <>
                    <div className="col-lg-2">
                      <label>Priority</label>
                      <select
                        className={`form-control`}
                        name="priority"
                        onChange={handleChangeFilterValues}
                        value={filterFormValues.priority}
                      >
                        <option value={""}>Select Priority</option>
                        <option value={"Low"}>Low</option>
                        <option value={"Medium"}>Medium</option>
                        <option value={"High"}> High</option>
                        <option value={"Critical"}>Critical</option>
                      </select>
                    </div>
                    <div className="col-lg-2">
                      <label>Status</label>
                      <select
                        value={filterFormValues.status}
                        className={`form-control`}
                        name="status"
                        onChange={handleChangeFilterValues}
                      >
                        <option value={""}>Select Status</option>
                        <option value={"In_Progress"}>In Progress</option>
                        <option value={"Resolved"}>Resolved</option>
                        <option value={"Closed"}> Closed</option>
                        <option value={"Reopened"}>Reopen</option>
                      </select>
                    </div>

                    <div className="col-lg-1 d-flex align-items-end">
                      <Button type="submit">Search</Button>
                    </div>
                    <div className="col-lg-1 d-flex align-items-end">
                      <Button onClick={resetFilter}>Reset</Button>
                    </div>
                    <div className="col-lg-5"></div>
                  </>
                ) : (
                  <>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <label>Search </label>
                        <InputGroup>
                          <FormControl
                            type="text"
                            className="form-control input-default"
                            placeholder="Search"
                            onChange={handleSearchValue}
                          />
                          {/* <InputGroup.Text onClick={() => {}}>
                            <FaSearch />
                          </InputGroup.Text> */}
                        </InputGroup>
                      </div>
                    </div>
                    <div className="col-lg-7"></div>
                  </>
                )}

                {/* Icons for Filter and Search */}
                <div className="col-lg-1 d-flex align-items-end">
                  <div className="d-flex align-items-center p-2 gap-2">
                    <CiFilter
                      size={30}
                      onClick={() => handleToggle("filter")}
                      style={{
                        cursor: "pointer",
                        color:
                          toggleSearchAndFilter === "filter"
                            ? "var(--primary)"
                            : "",
                      }}
                    />
                    <CiSearch
                      size={30}
                      onClick={() => handleToggle("search")}
                      style={{
                        cursor: "pointer",
                        color:
                          toggleSearchAndFilter === "search"
                            ? "var(--primary)"
                            : "",
                      }}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <div className="w-100 table-responsive">
              <div id="example_wrapper" className="dataTables_wrapper">
                <table id="example" className="display w-100 dataTable">
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Ticket No</th>
                      <th>Create At</th>
                      <th>Raised By</th>
                      <th>Atom ID</th>
                      <th>Ticket Category</th>
                      <th>Title</th>
                      <th>Description</th>
                      <th>Status</th>
                      <th>Priority</th>
                      <th>Attachement</th>
                      <th>Update Status</th>
                      <th>Update Priority</th>
                      <th>View Detail</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.data?.length > 0 ? (
                      data?.data?.map((item, index) => (
                        <tr key={index}>
                          <td>{(currentpage - 1) * 10 + index + 1}</td>

                          <td>{item.ticket_unique_id}</td>
                          <td>
                            {moment(item?.created_at).format(
                              "MMM DD, YY hh:mm:ss A"
                            )}{" "}
                            {/* e.g., "Nov 13, 24 04:35:22 PM" */}
                          </td>
                          <td>
                            {item?.main_category_name === "Institution" ? (
                              <Nav.Link
                                href={`/verification-form/${item.user_id}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {item.name_of_legel_entity}
                              </Nav.Link>
                            ) : item?.main_category_name === "Individual" ? (
                              <Nav.Link
                                href={`/verification-Individual-form/${item.user_id}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {item.name}
                              </Nav.Link>
                            ) : (
                              "N/A"
                            )}
                          </td>
                          <td>{item.atom_id}</td>
                          <td>{item.ticket_category_type}</td>
                          <td
                            className="text-wrap"
                            style={{ maxWidth: "200px" }}
                          >
                            {item.title}
                          </td>
                          <td
                            className="text-wrap"
                            style={{ maxWidth: "200px" }}
                          >
                            {item.description.length > 50
                              ? item.description.substring(0, 50) + "..."
                              : item.description}
                          </td>
                          <td style={{ color: getStatusColor(item.status) }}>
                            {item.status === "In_Progress"
                              ? "In Progress"
                              : item.status}
                          </td>
                          <td
                            style={{
                              color: getStatusColor(item.prority_level),
                            }}
                          >
                            {item.prority_level}
                          </td>
                          <td>
                            {item?.attachement_storage_url ? (
                              formatOFTicketRegex.test(
                                item?.attachement_storage_url
                              ) ? (
                                <img
                                  src={item?.attachement_storage_url}
                                  alt={item?.department}
                                  style={{ width: "50px", height: "auto" }}
                                  onClick={() =>
                                    window.open(
                                      item?.attachement_storage_url,
                                      "_blank"
                                    )
                                  }
                                />
                              ) : (
                                <a
                                  href={item?.attachement_storage_url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  View PDF
                                </a>
                              )
                            ) : (
                              "N/A"
                            )}
                          </td>
                          <td>
                            <select
                              onChange={(e) => handleStatusOfTicket(e, item)}
                              class="form-select form-select-sm"
                            >
                              <option value={""}>Select Status</option>
                              <option value={"In_Progress"}>In Progress</option>
                              <option value={"Resolved"}>Resolved</option>
                              <option value={"Closed"}> Closed</option>
                              <option value={"Reopened"}>Reopen</option>
                            </select>
                          </td>
                          <td>
                            <select
                              class="form-select form-select-sm"
                              onChange={(e) => handlePriotityOfTicket(e, item)}
                            >
                              <option value={""}>Select Priority</option>
                              <option value={"Low"}>Low</option>
                              <option value={"Medium"}>Medium</option>
                              <option value={"High"}> High</option>
                              <option value={"Critical"}>Critical</option>
                            </select>
                          </td>
                          <td>
                            <i
                              onClick={() => handleViewTicket("_", item)}
                              className="bi bi-eye-fill text-primary"
                            ></i>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="6" className="text-center">
                          Data is not available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <div className="d-flex justify-content-center py-2">
                  <PaginationComp
                    size={totalPages}
                    setCurrentPage={setCurrentPage}
                    currentpage={currentpage}
                    gutter={true}
                    variant={""}
                    bg={true}
                    circle={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-12">
        <div className="card">
          <ViewTicket
            // selectedTickedDeatils={selectedTickedDeatils}
            selectedTickedDeatils={
              selectedTickedDeatils || selectedTicketRef.current
            }
            show={show}
            handleClose={handleClose}
            // currentChatId={currentChatId}
            currentChatId={
              selectedTickedDeatils?.id || selectedTicketRef.current?.id
            }
            isLoading={isLoading}
            showfor={"admin"}
            onChangeFunc={handleResponseText}
            value={responseText}
            viewTicketData={viewTicketData}
            handleSubmitResponse={handleSubmitResponse}
            setUploadedFile={setUploadedFile}
            uploadedFile={uploadedFile}
            handleViewTicket={handleViewTicket}
            error={error}
          />
        </div>
      </div>
    </>
  );
}

export default Ticket;
