import React, { useEffect, useState } from "react";
import Select from "react-select";
import swal from "sweetalert";
import { getAllUserProfileList } from "../../../services/userApiService/userService";

const ProfileComp = ({ user_category_main_id, user_id }) => {
 
  const [profileImage, setProfileImage] = useState(
    "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
  );
  const [userData, setUserData] = useState({});
  const [inputBlog, setInputBlog] = useState([]);


  // Replace with your options
  const options2 = []; // Gender options
  const options3 = []; // Country options
  const options4 = []; // City options
  const id = localStorage.getItem('id');
  const userCategoryMain = localStorage.getItem('user_category_main');



  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);

  const handleChange = (e) => {
    let file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({ ...prevData, [name]: value }));
  }

  const handleSelectChange = (selectedOption, name) => {
    setUserData((prevData) => ({ ...prevData, [name]: selectedOption }));
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    swal("Saved", "", "success");
  };
  const fetchUserData = async () => {
    try {
      const response = await getAllUserProfileList(userCategoryMain, id);
      setUserData(response?.data?.data);

    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, [userCategoryMain, id]);


  return (
    <>
      <div className="row">
        <div className="col-xl-3 col-lg-4">
          <div className="card card-bx profile-card author-profile m-b30">
            <div className="card-body">
              <div className="p-5">
                <div className="author-profile">
                  <div
                    className="author-media"
                    style={{ position: "relative", width: "100%", height: "200px" }}
                  >
                    <img
                      src={userData?.organization_docs?.[9]?.doc_file || profileImage}
                      alt="Profile"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: "50%",
                      }}
                    />
                    {/* <div
                      className="upload-link"
                      title="Update"
                      style={{ position: "absolute", bottom: "10px", right: "10px" }}
                    >
                      <input
                        type="file"
                        className="update-file"
                        onChange={handleChange}
                        style={{ display: "none" }}
                      />
                      <i
                        className="fa fa-camera"
                        style={{ cursor: "pointer" }}
                        onClick={() => document.querySelector(".update-file").click()}
                      ></i>
                    </div> */}
                  </div>
                  <div className="author-info">
                    <h6 className="title">{userData?.first_name}</h6>
                    <span>{userData?.name_of_legel_entity}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer">
              {/* Other footer elements */}
            </div>
          </div>
        </div>
        <div className="col-xl-9 col-lg-8">
          <div className="card profile-card card-bx m-b30">
            {/* <div className="card-header">
              <h6 className="title"> Account Setup </h6>
            </div> */}
            <form className="profile-form">
              <div className="card-body">
                <div className="row">
                  {inputBlog?.map((item, ind) => (
                    <div className="col-sm-6 m-b30" key={ind}>
                      <label className="form-label">{item.label}</label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={item.value}
                      />
                    </div>
                  ))}
                  <div className="col-sm-6 m-b30">
                    <label className="form-label">Registration Number</label>
                    <input
                      type="text"
                      onChange={handleInputChange}
                      className="form-control"
                      value={userData?.organization?.registration_number}
                      name="registration number"

                     
                    />
                  </div>

                  <div className="col-sm-6 m-b30">
                    <label className="form-label">Email address</label>
                    <input
                      type="text"
                      className="form-control"
                      value={userData?.email || ""}
                      name="email"
                      onChange={handleInputChange}
                      
                    />
                  </div>

                  <div className="col-sm-6 m-b30">
                    <label className="form-label">Phone</label>
                    <input
                      type="text"
                      onChange={handleInputChange}
                      className="form-control"
                      value={userData?.organization?.mobile}
                      name="phone"
                      
                    />
                  </div>


                  <div className="col-sm-6 m-b30">
                    <label className="form-label">Address</label>
                    <input
                      type="text"
                      onChange={handleInputChange}
                      className="form-control"
                      value={userData?.organization?.address}
                      name="address"
                    />
                  </div>

                  <div className="col-sm-6 m-b30">
                    <label className="form-label">Pincode</label>
                    <input
                      type="text"
                      onChange={handleInputChange}
                      className="form-control"
                      value={userData?.organization?.pincode}
                      name="pincode"
                    />
                  </div>
                  <div className="col-sm-6 m-b30">
                    <label className="form-label">Country</label>
                    <input
                      type="text"
                      className="form-control"
                      value={userData?.organization?.country_name || ""}
                      readOnly
                    />
                  </div>
                  <div className="col-sm-6 m-b30">
                    <label className="form-label">State</label>
                    <input
                      type="text"
                      className="form-control"
                      value={userData?.organization?.state_name || ""}
                      readOnly
                    />
                  </div>
                  <div className="col-sm-6 m-b30">
                    <label className="form-label">City</label>
                    <input
                      type="text"
                      className="form-control"
                      value={userData?.organization?.city_name || ""}
                      readOnly
                    />
                  </div>
                </div>
              </div>
              {/* <div className="card-footer justify-content-end">
               
                <button className="btn btn-primary" onClick={handleSubmit}>
                  Save
                </button>
              </div> */}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileComp;
