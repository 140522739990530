// event calendar relared api's

// import axios from "axios";
import {
  EDIT_EVENT,
  GET_ALL_EVENT_CALENDAR_DATA,
  GET_EVENT_TYPE_LIST,
  POST_EVENT,
  PREVIEW_EVENT_CALENDAR_DATA,
  UPDATE_EVENT_STATUS,
} from "../../../config/adminUrlConfig";
import { ApiService, FileUploadService } from "../../../config/apiServices";

export const addEventCalendar = (paylaod) => {
  try {
    return FileUploadService.post(POST_EVENT, paylaod);
  } catch (error) {
    return error.message;
  }
};
export const editEventCalendar = (paylaod) => {
  try {
    return FileUploadService.post(EDIT_EVENT, paylaod);
  } catch (error) {
    return error.message;
  }
};
export const updateEventCalendarStatus = (id, status) => {
  try {
    return ApiService.get(`${UPDATE_EVENT_STATUS}/${id}/${status}`);
  } catch (error) {
    return error.message;
  }
};
export const getAllEventCalendarData = (payload = {}) => {
  try {
    let url = `${GET_ALL_EVENT_CALENDAR_DATA}?`;
    if (payload.page) {
      url += `page=${payload?.page}&`;
    }
    if (payload.searchValue) {
      url += `search=${payload?.searchValue}&`;
    }
    if (payload.type_id) {
      url += `type_id=${payload?.type_id}&`;
    }
    return ApiService.post(url);
  } catch (error) {
    return error.message;
  }
};

export const viewEventCalendarDetails = (id) => {
  return ApiService.get(`${PREVIEW_EVENT_CALENDAR_DATA}/${id}`);
};

export const eventCalendarTypeList = () => {
  return ApiService.get(GET_EVENT_TYPE_LIST);
};
