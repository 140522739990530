// import axios from "axios";
import {
  GET_ALL_WEBSITE_NOTIFICATION,
  GET_CATEGORIES,
  GET_CATEGORIES_TYPE,
  GET_CATEGORY_SUB_TYPE,
  GET_COMMUNICATION_DATA,
  GET_NEW_NOTIFICATION_DETAILS_BY_ID,
  GET_NOTIFICATION_FOR_VALUES,
  POST_DATA,
  UPDATE_USER_NOTIFICATION_STATUS,
  UPDATE_WEBSITE_NOTIFICATION_STATUS,
  VIEW_WEBSITE_NOTIFICATION,
} from "../../../config/adminUrlConfig";
import { ApiService, FileUploadService } from "../../../config/apiServices";

// function axiosApiSend(method, endPoint, paylaod = {}) {
//   return axios({
//     method: method,
//     url: `${process.env.REACT_APP_BASE_URL}/${endPoint}`,
//     data: paylaod,
//     headers: {
//       Authorization: `Bearer ${localStorage.getItem("access_token_admin")}`,
//     },
//   });
// }
export const getNotificationValues = () => {
  return ApiService.get(GET_NOTIFICATION_FOR_VALUES);
};
export const getMainCategories = () => {
  // return axiosApiSend("get", GET_CATEGORIES);
  return ApiService.get(GET_CATEGORIES);
};
export const getCategories = () => {
  // return axiosApiSend("get", GET_CATEGORIES_TYPE);

  return ApiService.get(GET_CATEGORIES_TYPE);
};

export const getSubCategoryType = (categoryvalue) => {
  // return axiosApiSend("get", `${GET_CATEGORY_SUB_TYPE}/${categoryvalue.id}`);

  return ApiService.get(`${GET_CATEGORY_SUB_TYPE}/${categoryvalue.id}`);
};

export const postCommunicateData = (payload) => {
  // return axiosApiSend("post", POST_DATA, payload);

  return FileUploadService.post(POST_DATA, payload);
};

export const getCommunicationData = (payload = {}, page) => {
  // return axiosApiSend("post", GET_COMMUNICATION_DATA, payload);
  let url = `${GET_COMMUNICATION_DATA}?`;
  if (page) {
    url += `page=${page}&`;
  }
  try {
    return ApiService.post(url, payload);
  } catch (error) {
    return error.message;
  }
};

export const getNotificationDetailsById = (id) => {
  // return axiosApiSend("get", `${GET_NEW_NOTIFICATION_DETAILS_BY_ID}/${id}`);
  return ApiService.get(`${GET_NEW_NOTIFICATION_DETAILS_BY_ID}/${id}`);
};
export const getWebsiteNotificationDetailsById = (id) => {
  // console.log(id);
  // return axiosApiSend("get", `${VIEW_WEBSITE_NOTIFICATION}/${id}`);
  return ApiService.get(`${VIEW_WEBSITE_NOTIFICATION}/${id}`);
};

export const getAllNotificationDetails = (payload = {}, page) => {
  // return axiosApiSend("post", `${GET_ALL_WEBSITE_NOTIFICATION}`, payload);
  console.log(page);
  let url = `${GET_ALL_WEBSITE_NOTIFICATION}?`;
  if (page) {
    url += `page=${page}&`;
  }
  console.log(url);
  try {
    return ApiService.post(url, payload);
  } catch (error) {
    return error.message;
  }
};
export const updateUserNotificationStatus = (id, statusValue) => {
  // return axiosApiSend(
  //   "get",
  //   `${UPDATE_USER_NOTIFICATION_STATUS}/${id}/${statusValue}`
  // );

  return ApiService.get(
    `${UPDATE_USER_NOTIFICATION_STATUS}/${id}/${statusValue}`
  );
};
export const updateWebsiteNotificationStatus = (id, statusValue) => {
  // return axiosApiSend(
  //   "get",
  //   `${UPDATE_WEBSITE_NOTIFICATION_STATUS}/${id}/${statusValue}`
  // );
  return ApiService.get(
    `${UPDATE_WEBSITE_NOTIFICATION_STATUS}/${id}/${statusValue}`
  );
};
